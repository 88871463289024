<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date To</label>
            <b-input
              id="date_from"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Company</label>
            <b-select
              v-model="filter.company"
              :options="filterCompanies"
              @change="onCompanyFilterChange"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Organization</label>
            <b-select
              v-model="filter.organization"
              :options="filterOrganizations"
              @change="onFilterChange"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Status</label>
            <b-select
              v-model="filter.status"
              :options="list.statuses"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Funding Type</label>
            <b-select
              v-model="filter.funding_type"
              :options="fundingTypes"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <json-excel
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn btn-outline-success btn-sm"
            :fetch="myExporter"
            :before-generate="startExport"
            :before-finish="finishExport"
            :disabled="state.exporting || tableSettings.totalRows < 1"
            :fields="exportAs.excel.fields"
            :escape-csv="exportAs.excel.settings.escape"
            :type="exportAs.excel.settings.type"
            :name="excelFileName"
            :header="exportAs.excel.header"
          >
            <span
              v-if="state.exporting"
              class="align-middle"
            >Exporting <i class="ri-loader-2-line" /></span>
            <span
              v-else
              class="align-middle"
            >Export as Excel</span>
          </json-excel>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowViewForm(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal View Form -->
    <b-modal
      id="modal-view-form"
      scrollable
      no-close-on-backdrop
      title="View Detail"
      size="lg"
    >
      <b-card no-body>
        <form
          novalidate
          role="form"
        >
          <b-row>

            <!-- Requester -->
            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="created_at">
                      Requested At
                    </label>
                    <b-input
                      id="created_at"
                      v-model="maintenanceHistory.created_at"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="requestor_name">
                      Requested By
                    </label>
                    <b-input
                      id="requestor_name"
                      v-model="maintenanceHistory.requestor_name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Company & Organizations -->
            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="company_name">
                      Company
                    </label>
                    <b-input
                      id="company_name"
                      v-model="maintenanceHistory.company_name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="organization_name">
                      Organization
                    </label>
                    <b-input
                      id="organization_name"
                      v-model="maintenanceHistory.organization_name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Fund Type & Amounts -->
            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="funding_type">
                      Funding Type
                    </label>
                    <b-input
                      id="funding_type"
                      v-model="fundingTypeValue"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="amount">
                      Estimated Amount
                    </label>
                    <b-input
                      id="amount"
                      v-model="maintenanceHistory.estimated_amount"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="actual_amount">
                      Actual Amount
                    </label>
                    <b-input
                      id="actual_amount"
                      v-model="maintenanceHistory.actual_amount"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Vehicle -->
            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="vehicle_license_plate">
                      Vehicle Plate Number
                    </label>
                    <b-input
                      id="vehicle_license_plate"
                      v-model="maintenanceHistory.vehicle_license_plate"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="vehicle_name">
                      Vehicle Name
                    </label>
                    <b-input
                      id="vehicle_name"
                      v-model="maintenanceHistory.vehicle_name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="vehicle_category_name">
                      Vehicle Category
                    </label>
                    <b-input
                      id="vehicle_category_name"
                      v-model="maintenanceHistory.vehicle_category_name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="vehicle_type">
                      Vehicle Type
                    </label>
                    <b-input
                      id="vehicle_type"
                      v-model="vehicleIsValue"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Damage Items and Reasons-->
            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="damaged_item_specifications">
                      Ano ang Nasira?
                    </label>
                    <b-textarea
                      id="damaged_item_specifications"
                      v-model="maintenanceHistory.damaged_item_specifications"
                      rows="6"
                      max-rows="8"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="cause_of_damage">
                      Rason ng Pagkasira?
                    </label>
                    <b-textarea
                      id="cause_of_damage"
                      v-model="maintenanceHistory.cause_of_damage"
                      rows="6"
                      max-rows="8"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <!-- Attachments -->
            <b-col
              v-if="maintenanceHistory.attachments.length > 0"
              cols="12"
            >
              <label>Attachments</label>
              <b-table
                class="mt-1"
                :fields="attachmentTableSettings.fields"
                :items="maintenanceHistory.attachments"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>

            <!-- Signatories -->
            <b-col
              cols="12"
            >
              <b-row>
                <!-- Noted By -->
                <b-col
                  v-if="maintenanceHistory.approver_level >= 1"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label for="noted_by_at">
                          Last Odometer
                        </label>
                        <b-input
                          id="noted_by_at"
                          v-model="maintenanceHistory.last_odometer"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label for="current_odometer">
                          Current Odometer
                        </label>
                        <b-input
                          id="current_odometer"
                          v-model="maintenanceHistory.current_odometer"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label for="noted_by_at">
                          {{ notedByStatus }} At
                        </label>
                        <b-input
                          id="noted_by_at"
                          v-model="maintenanceHistory.noted_by_at"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label for="noted_by_name">
                          {{ notedByStatus }} By
                        </label>
                        <b-input
                          id="noted_by_name"
                          v-model="maintenanceHistory.noted_by_name"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                    >
                      <b-form-group>
                        <label for="noted_by_remarks">
                          {{ notedByStatus }} By Remarks
                        </label>
                        <b-textarea
                          id="noted_by_remarks"
                          v-model="maintenanceHistory.noted_by_remarks"
                          rows="6"
                          max-rows="8"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="maintenanceHistory.maintenance_items.length > 0"
                      cols="12"
                    >
                      <label>Parte o Materyales na Kelangan</label>
                      <b-table
                        class="mt-1"
                        :fields="partsNeededTableSettings.fields"
                        :items="maintenanceHistory.maintenance_items"
                        responsive
                        striped
                        hover
                      >
                        <template #cell()="data">
                          <div class="text-nowrap">
                            {{ data.value }}
                          </div>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Approved By -->
                <b-col
                  v-if="notedByStatus === 'Noted' && maintenanceHistory.approver_level >= 2"
                  cols="12"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label for="approved_by_at">
                          {{ approvedByStatus }} At
                        </label>
                        <b-input
                          id="approved_by_at"
                          v-model="maintenanceHistory.approved_by_at"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label for="approved_by_name">
                          {{ approvedByStatus }} By
                        </label>
                        <b-input
                          id="approved_by_name"
                          v-model="maintenanceHistory.approved_by_name"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                    >
                      <b-form-group>
                        <label for="approved_by_remarks">
                          {{ approvedByStatus }} By Remarks
                        </label>
                        <b-textarea
                          id="approved_by_remarks"
                          v-model="maintenanceHistory.approved_by_remarks"
                          rows="6"
                          max-rows="8"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Purchased By -->
                <b-col
                  v-if="maintenanceHistory.funding_type === 'cash-advance' && notedByStatus === 'Noted' && approvedByStatus === 'Approved' && maintenanceHistory.approver_level >= 3"
                  cols="12"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label for="purchased_by_at">
                          {{ purchasedByStatus }} At
                        </label>
                        <b-input
                          id="purchased_by_at"
                          v-model="maintenanceHistory.purchased_by_at"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label for="purchased_by_name">
                          {{ purchasedByStatus }} By
                        </label>
                        <b-input
                          id="purchased_by_name"
                          v-model="maintenanceHistory.purchased_by_name"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                    >
                      <b-form-group>
                        <label for="purchased_by_remarks">
                          {{ purchasedByStatus }} By Remarks
                        </label>
                        <b-textarea
                          id="purchased_by_remarks"
                          v-model="maintenanceHistory.purchased_by_remarks"
                          rows="6"
                          max-rows="8"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Completed By -->
                <b-col
                  v-if="maintenanceHistory.status === 'Completed'"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label for="completed_by_at">
                          Completed At
                        </label>
                        <b-input
                          id="completed_by_at"
                          v-model="maintenanceHistory.completed_by_at"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group>
                        <label for="completed_by_name">
                          Completed By
                        </label>
                        <b-input
                          id="completed_by_name"
                          v-model="maintenanceHistory.completed_by_name"
                          type="text"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </form>
      </b-card>

      <template #modal-footer="{ cancel }">
        <b-overlay
          :show="state.busy"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block mr-1"
        >
          <b-button
            variant="warning"
            :disabled="state.busy"
            @click="onPrintPreview()"
          >
            Print
          </b-button>
        </b-overlay>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
    <!-- modal preview image -->
    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${file.name}]`"
      size="lg"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.preview"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-print"
      no-close-on-backdrop
      scrollable
      size="xl"
    >
      <div style="height: 350%;">
        <iframe
          style="height: 100%; width: 100%"
          :src="pdfData"
        />
      </div>

      <div id="table-attachments">
        <table class="col-12 mt-5">
          <thead>
            <tr>
              <th class="h2">
                <div class="mb-5">
                  Attachments:
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="attachment in maintenanceHistory.attachments"
              :key="attachment.file_hash_name"
            >
              <td
                class="p-0 m-0"
              >
                <b-row class="mb-5">
                  <b-col
                    class="text-center"
                    cols="12"
                  >
                    <b-img
                      fluid
                      :src="attachment.url"
                      style="height: calc(100vh / 2.7); margin-bottom: 50px;"
                    />
                  </b-col>
                  <b-col
                    class="h3 text-center"
                    cols="12"
                  >
                    {{ attachment.file_original_name }}
                  </b-col>
                </b-row>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          v-print="'#table-attachments'"
          variant="success"
        >
          Print Attachments
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Preview
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { PurchasingReportService, PurchasingExportService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import helper from '@/mixins/helper'
import Ripple from 'vue-ripple-directive'
import JsonExcel from 'vue-json-excel'
import print from 'vue-print-nb'

export default {
  name: 'PurchasingReportsVehicleRequestHistories',

  middleware: ['auth', 'purchasing'],

  metaInfo () {
    return {
      title: 'Reports - Vehicle Request Histories'
    }
  },

  components: {
    JsonExcel
  },

  directives: {
    Ripple,
    print
  },

  mixins: [formatter, url, helper],

  data () {
    return {
      state: {
        busy: false,
        file: {
          previewing: false
        },
        exporting: false
      },
      file: {
        name: '',
        preview: null
      },
      list: {
        companies: [],
        organizations: [],
        statuses: [
          { text: 'All', value: 'All' },
          { text: 'Approved', value: 'Approved' },
          { text: 'Disapproved', value: 'Disapproved' }
        ]
      },
      filter: {
        company: 'All',
        organization: 'All',
        funding_type: 'All',
        status: this.$route.params?.purchased_by_status || 'All',
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      maintenanceHistory: {
        id: 0,
        document_number: '',
        actual_amount: 0,
        estimated_amount: 0,
        funding_type: 'petty-cash',
        company_name: '',
        organization_name: '',
        position_name: 'Requester',
        requestor_name: '',
        user_name: '',
        vehicle_name: '',
        vehicle_category_name: '',
        vehicle_license_plate: '',
        vehicle_type: 'owned',
        damaged_item_specifications: '',
        cause_of_damage: '',
        status: 'Processing',
        attachments: [],
        last_odometer: 0,
        current_odometer: 0,
        noted_by_name: '',
        noted_by_remarks: '',
        noted_by_at: '',
        noted_by_status: 'Pending',
        maintenance_items: [],
        approved_by_name: '',
        approved_by_remarks: '',
        approved_by_at: '',
        approved_by_status: 'Pending',
        purchased_by_name: '',
        purchased_by_remarks: '',
        purchased_by_at: '',
        purchased_by_status: 'Pending',
        completed_by_name: '',
        completed_by_at: '',
        approver_level: 0,
        created_at: '',
        updated_at: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'requested at', sortable: true },
          { key: 'document_number' },
          { key: 'requestor_name', label: 'Requested By' },
          { key: 'vehicle_license_plate', label: 'License Plate' },
          { key: 'vehicle_category_name', label: 'Vehicle Category' },
          'status',
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true },
          'purchased_by_status',
          { key: 'purchased_by_at', label: 'Purchased At', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      },
      partsNeededTableSettings: {
        fields: [
          { key: 'name' },
          { key: 'quantity' },
          { key: 'unit_of_measurement' },
          { key: 'amount' },
          { key: 'funding_type', formatter: this.findFundingType },
          { key: 'supplier_name' },
          { key: 'document_number' },
          { key: 'item_from', formatter: this.findPartsFrom },
          { key: 'item_status', formatter: this.findItemStatus },
          { key: 'last_request', formatter: this.checkLastRequest }
        ]
      },
      exportAs: {
        excel: {
          header: 'Maintenance / Repair Request Histories',
          settings: {
            type: 'xls',
            escape: false
          },
          fields: {
            'Request Number': {
              field: 'document_number'
            },
            Company: {
              field: 'company_name'
            },
            Organization: {
              field: 'organization_name'
            },
            Requester: {
              field: 'user_name'
            },
            'Requested For': {
              field: 'requestor_name'
            },
            'Vehicle Category': {
              field: 'vehicle_category_name'
            },
            Vehicle: {
              field: 'vehicle_name'
            },
            'Vehicle License Plate': {
              field: 'vehicle_license_plate'
            },
            Item: {
              field: 'maintenance_items',
              callback: rows => this.generateTableRows(rows, 'name')
            },
            Quantity: {
              field: 'maintenance_items',
              callback: rows => this.generateTableRows(rows, 'quantity')
            },
            'Unit Of Measurement': {
              field: 'maintenance_items',
              callback: rows => this.generateTableRows(rows, 'unit_of_measurement')
            },
            'Estimated Amount': {
              field: 'estimated_amount'
            },
            'Funding Type': {
              field: 'maintenance_items',
              callback: rows => this.generateTableRows(rows, 'funding_type')
            },
            'Inventory / Purchase': {
              field: 'maintenance_items',
              callback: rows => this.generateTableRows(rows, 'item_from')
            },
            'Last Odometer': {
              field: 'last_odometer'
            },
            'Current Odometer': {
              field: 'current_odometer'
            },
            'Requested At': {
              field: 'created_at',
              callback: value => this.dateTimeFormatter(value)
            },
            'Approved At': {
              field: 'approved_by_at',
              callback: value => this.dateTimeFormatter(value)
            }
          }
        }
      },
      pdfData: ''
    }
  },

  computed: {
    excelFileName () {
      return `Vehicle-Maintenance-Repair-Requests-${this.filter.date_from}-${this.filter.date_to}.xls`
    },

    fundingTypeValue () {
      return this.findFundingType(this.maintenanceHistory.funding_type)
    },

    vehicleIsValue () {
      return this.findVehicleIs(this.maintenanceHistory.vehicle_type)
    },

    filterOrganizations () {
      return [{ value: 'All', text: 'All' }].concat(
        this.list.organizations.filter(
          organization => {
            if (this.filter.company !== 'All') {
              return organization.company === this.filter.company
            }

            return organization
          }
        )
      )
    },

    filterCompanies () {
      return [{ value: 'All', text: 'All' }].concat(this.list.companies)
    },

    notedByStatus () {
      return ['Approved', 'Pending'].includes(this.maintenanceHistory.noted_by_status) ? 'Noted' : 'Disapproved'
    },

    approvedByStatus () {
      return ['Approved', 'Pending'].includes(this.maintenanceHistory.approved_by_status) ? 'Approved' : 'Disapproved'
    },

    purchasedByStatus () {
      return ['Approved', 'Pending'].includes(this.maintenanceHistory.purchased_by_status) ? 'Purchased' : 'Disapproved'
    }

  },

  mounted () {
    core.index()
    this.fetchList()
  },

  methods: {

    generateTableRows (rows, key) {
      let tableRows = ''

      rows.forEach(current => {
        switch (key) {
          case 'funding_type':
            tableRows += `<tr><td>${this.findFundingType(current[key])}</td></tr>`
            break
          case 'item_from':
            tableRows += `<tr><td>${this.findPartsFrom(current[key])}</td></tr>`
            break
          case 'item_status':
            tableRows += `<tr><td>${this.findItemStatus(current[key])}</td></tr>`
            break
          default:
            tableRows += `<tr><td>${current[key]}</td></tr>`
            break
        }
      })

      return `<table>${tableRows}</table>`
    },

    async onPrintPreview () {
      this.state.busy = true
      const pdfDocument = this.createPDFOptions({
        info: {
          title: 'Repair Form',
          author: '',
          creator: '',
          subject: ''
        },
        requestedBy: {
          id: this.maintenanceHistory.id,
          document_number: this.maintenanceHistory.document_number,
          organization_name: this.maintenanceHistory.organization_name,
          position_name: this.maintenanceHistory.position_name,
          name: this.maintenanceHistory.requestor_name,
          date: this.dateFormatterLong(this.maintenanceHistory.created_at),
          time: this.dateTimeFormatterLong(this.maintenanceHistory.created_at),
          vehicle_license_plate: this.maintenanceHistory.vehicle_license_plate,
          damaged_item_specifications: this.maintenanceHistory.damaged_item_specifications,
          cause_of_damage: this.maintenanceHistory.cause_of_damage,
          maintenance_items: this.maintenanceHistory.maintenance_items
        },
        notedBy: {
          position_name: this.maintenanceHistory.noted_by_pisition,
          name: this.maintenanceHistory.noted_by_name,
          remarks: this.maintenanceHistory.noted_by_remarks,
          time: this.dateTimeFormatterLong(this.maintenanceHistory.noted_by_at),
          organization_name: ' ',
          last_odometer: this.maintenanceHistory.last_odometer,
          current_odometer: this.maintenanceHistory.current_odometer
        },
        approvedBy: {
          position_name: this.maintenanceHistory.approved_by_position,
          name: this.maintenanceHistory.approved_by_name,
          remarks: this.maintenanceHistory.approved_by_remarks,
          time: this.dateTimeFormatterLong(this.maintenanceHistory.approved_by_at),
          organization_name: ' '
        },
        attachments: this.maintenanceHistory.attachments
      })

      pdfDocument.getDataUrl(dataUrl => {
        this.pdfData = dataUrl
        this.state.busy = false
      })

      this.$bvModal.show('modal-preview-print')
    },

    async myProvider (ctx) {
      try {
        const { data } = await PurchasingReportService.getVehicleRequestHistories(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_company=${this.filter.company}&filter_organization=${this.filter.organization}&filter_status=${this.filter.status}&funding_type=${this.filter.funding_type}&filter_from=${this.filter.date_from}&filter_to=${this.filter.date_to}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items.map(maintenance => {
          if (maintenance.attachments.length > 0) {
            maintenance.attachments.map(async attachment => {
              attachment.url = this.getImageUrl(`${maintenance.attachments_path}/${attachment.file_hash_name}`)
              return attachment
            })
          }
          return maintenance
        })
      } catch {
        return []
      }
    },

    async myExporter () {
      try {
        const { data } = await PurchasingExportService.getVehicleRequestHistories(`filter_company=${this.filter.company}&filter_organization=${this.filter.organization}&filter_status=${this.filter.status}&funding_type=${this.filter.funding_type}&filter_from=${this.filter.date_from}&filter_to=${this.filter.date_to}&filter_text=${this.tableSettings.filter}`)
        return data
      } catch {
        this.state.exporting = false
        return []
      }
    },

    startExport () {
      this.state.exporting = true
    },

    finishExport () {
      this.state.exporting = false
    },

    async fetchList () {
      await SharedListService.getCompanies().then(({ data }) => {
        this.list.companies = data.map(({ id, company_name }) => ({
          text: company_name,
          value: id
        }))
      })

      await SharedListService.getOrganizations().then(({ data }) => {
        this.list.organizations = data.map(({ company_id, id, organization_name }) => ({
          company: company_id,
          text: organization_name,
          value: id
        }))
      })
    },

    onCompanyFilterChange () {
      this.filter.organization = 'All'
      this.$refs.table.refresh()
    },

    onFilterChange () {
      this.$refs.table.refresh()
    },

    onShowViewForm (row) {
      this.maintenanceHistory.id = row.id
      this.maintenanceHistory.document_number = row.document_number

      this.maintenanceHistory.estimated_amount = row.estimated_amount
      this.maintenanceHistory.actual_amount = row.actual_amount
      this.maintenanceHistory.funding_type = row.funding_type

      this.maintenanceHistory.company_name = row.company_name
      this.maintenanceHistory.organization_name = row.organization_name
      this.maintenanceHistory.requestor_name = row.requestor_name
      this.maintenanceHistory.user_name = row.user_name

      this.maintenanceHistory.vehicle_name = row.vehicle_name
      this.maintenanceHistory.vehicle_category_name = row.vehicle_category_name
      this.maintenanceHistory.vehicle_license_plate = row.vehicle_license_plate
      this.maintenanceHistory.vehicle_type = row.vehicle_type

      this.maintenanceHistory.damaged_item_specifications = row.damaged_item_specifications
      this.maintenanceHistory.cause_of_damage = row.cause_of_damage

      this.maintenanceHistory.attachments_path = row.attachments_path
      this.maintenanceHistory.attachments = row.attachments

      this.maintenanceHistory.status = row.status

      this.maintenanceHistory.last_odometer = row.last_odometer
      this.maintenanceHistory.current_odometer = row.current_odometer

      this.maintenanceHistory.noted_by_name = row.noted_by_name
      this.maintenanceHistory.noted_by_pisition = row.noted_by_pisition
      this.maintenanceHistory.noted_by_remarks = row.noted_by_remarks
      this.maintenanceHistory.noted_by_at = row.noted_by_at
      this.maintenanceHistory.noted_by_status = row.noted_by_status
      this.maintenanceHistory.maintenance_items = row.maintenance_items

      this.maintenanceHistory.approved_by_name = row.approved_by_name
      this.maintenanceHistory.approved_by_position = row.approved_by_position
      this.maintenanceHistory.approved_by_remarks = row.approved_by_remarks
      this.maintenanceHistory.approved_by_at = row.approved_by_at
      this.maintenanceHistory.approved_by_status = row.approved_by_status

      if (row.funding_type === 'cash-advance') {
        this.maintenanceHistory.purchased_by_name = row.purchased_by_name
        this.maintenanceHistory.purchased_by_remarks = row.purchased_by_remarks
        this.maintenanceHistory.purchased_by_at = row.purchased_by_at
        this.maintenanceHistory.purchased_by_status = row.purchased_by_status
      }

      this.maintenanceHistory.approver_level = row.approver_level
      this.maintenanceHistory.completed_by_name = row.completed_by_name
      this.maintenanceHistory.completed_by_at = row.completed_by_at

      this.maintenanceHistory.created_at = this.dateTimeFormatter(row.created_at)
      this.maintenanceHistory.updated_at = this.dateTimeFormatter(row.updated_at)

      this.$bvModal.show('modal-view-form')
    },

    onPreviewAttachment (item) {
      this.file.name = item.file_original_name
      this.file.preview = item.url
      this.$bvModal.show('modal-preview-image')
    }
  }
}
</script>
